<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['skinType'])">
      <single-answer-question
        title="label.mySkinType"
        :value="skinType"
        :options="skinTypeOptions"
        @input="onFieldChange('skinType', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import { camelCase } from 'lodash';

import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap.vue';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion.vue';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { SKIN_COLOR, SKIN_TYPE } from '@/modules/questionnaire/api/constants';

const SKIN_BURNS_EASILY = [SKIN_COLOR.VERY_PALE_WHITE, SKIN_COLOR.PALE_WHITE, SKIN_COLOR.WHITE];

const SKIN_BURNS_MINIMALLY = [
  SKIN_COLOR.WHITE,
  SKIN_COLOR.LIGHT_BROWN,
  SKIN_COLOR.MEDIUM_BROWN,
  SKIN_COLOR.DARK_BROWN,
  SKIN_COLOR.BLACK
];

export default {
  name: 'PhototypeTemplate',
  components: { SingleAnswerQuestion, QuestionWithErrorWrap },
  mixins: [stepTemplateMixin],
  props: {
    skinType: {
      type: String,
      required: true
    },
    skinColor: {
      type: String,
      required: true
    }
  },
  computed: {
    skinTypeOptions() {
      return Object.values(SKIN_TYPE)
        .filter(skinType => this.skinTypeVisible(skinType))
        .map(skinType => ({
          value: skinType,
          text: `skinType.${camelCase(skinType)}`,
          hint: `info.skinType.${camelCase(skinType)}`
        }));
    }
  },
  methods: {
    skinTypeVisible(value) {
      switch (value) {
        case SKIN_TYPE.ALWAYS_BURN:
        case SKIN_TYPE.BURN_EASILY:
          return SKIN_BURNS_EASILY.includes(this.skinColor);
        case SKIN_TYPE.BURN_MODERATELY:
          return this.skinColor !== SKIN_COLOR.BLACK;
        case SKIN_TYPE.BURN_MINIMALLY:
        case SKIN_TYPE.NEVER_BURN:
          return SKIN_BURNS_MINIMALLY.includes(this.skinColor);
        default:
          return this.skinColor === SKIN_COLOR.BLACK;
      }
    }
  }
};
</script>
